import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageNavbar from "../components/imageNavbar"

// const imgDanielLina=require('../images/homepage/start_nude_1x_1.png')

const images1=[
  {img:require('../images/homepage/start_nude_1x_1.png'), 
    backgroundColor:'rgba(0, 0, 255, 0.5)', 
    link:'', 
    title:{EN:'Page 1', SV:'Sida 1'},
    helpText:{EN:'This the very help text for page 3', 
              SV:'Cursos, Registro, Baile social, Lecciones, Acuredo de negocios, Maestro'
    },
  },
  {img:require('../images/homepage/start_nude_1x_2.png'), 
  backgroundColor:'rgba(0, 255, 0, 0.5)', 
  link:'/page-2', 
    title:{EN:'Page 2', SV:'Sida 2'},
    helpText:{EN:'This the very help text for page 3', 
              SV:'Calendar with acitivities',
    }, 
  },
  {img:require('../images/homepage/start_nude_1x_3.png'), 
  backgroundColor:'rgba(255, 0, 255, 0.5)', 
    link:'/page-3/', 
    title:{EN:'Page 3', SV:'Sida 3'},
    helpText:{EN:'This the very help text for page 3', 
              SV:'Skoaffär / Showroom',
    }, 
  },
  {img:require('../images/homepage/start_nude_1x_4.png'), 
    backgroundColor:'rgba(255, 255, 0, 0.5)', 
    opacity:0.6, 
    link:'/page-4/', 
    title:{EN:'Page 4', SV:'Sida 4'},
    helpText:{EN:'This the very help text for page 4', 
              SV:'Festlokal för uthyrning',
    }, 
  },
]

const images2=[
  {img:require('../images/homepage/start_nude_1x_1.png'), 
    backgroundColor:'rgba(0, 0, 255, 0.5)', 
    link:'', 
    title:{EN:'Page 1', SV:'Sida 1'},
    helpText:{EN:'This is a help text for page 1', 
              SV:'Cursos, Registro, Baile social, Lecciones, Acuredo de negocios, Maestro'
    },
  },
  {img:require('../images/homepage/start_nude_1x_2.png'), 
  backgroundColor:'rgba(0, 255, 0, 0.5)', 
  link:'/page-2', 
    title:{EN:'Page 2', SV:'Sida 2'},
    helpText:{EN:'This is a help text for page 2', 
              SV:'Calendar with acitivities',
    }, 
  },
]

/*
const Timeline = () => 
<div className="timeline">
  <header className="timeline-header">
    <span className="tag is-medium is-primary"  style={{borderRadius:32}}>Start</span>
  </header>
  <div className="timeline-item is-primary">
    <div className="timeline-marker is-primary"></div>
    <div className="timeline-content">
      <p className="heading">January 2016</p>
      <p>Timeline content - Can include any HTML element</p>
    </div>
  </div>
  <div className="timeline-item is-warning">
    <div className="timeline-marker is-warning is-image is-64x64">
      <img src={imgDanielLina} style={{height:64}} alt='No image' />
    </div>
    <div className="timeline-content">
      <p className="heading">February 2016</p>
      <p>Timeline content - Can include any HTML element</p>
    </div>
  </div>
  <header className="timeline-header">
    <span className="tag is-primary">2017</span>
  </header>
  <div className="timeline-item is-danger">
    <div className="timeline-marker is-danger is-icon">
      <i className="fa fa-flag"></i>
    </div>
    <div className="timeline-content">
      <p className="heading">March 2017</p>
      <p>Timeline content - Can include any HTML element</p>
    </div>
  </div>
  <header className="timeline-header">
    <span className="tag is-medium is-primary">End</span>
  </header>
</div>
*/ 

const Page = () => (
  <Layout>
    <SEO title="Sidan fyra" />
    <h1>This page just show some Image Navigations</h1>
    <p>Example of two menus</p>
    <ImageNavbar images={images1} />
    <ImageNavbar images={images2} />
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default Page
