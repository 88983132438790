import React, {useState} from "react"
import { Link } from "gatsby"

const ImageNavbar = ({images}) => {
  const [hover, setHover] = useState({})  
  return(
    <div className="columns">
        {images.map((it, idx) =>
        <div 
            className="column" 
            style={{
                backgroundImage:hover.index!==idx||hover.index===undefined?`url(${it.img})`:null, 
                opacity:hover.index===idx?1.0:0.7,
                //opacity:hover.index!==idx?1.0:0.0, 
                //backgroundColor:hover.index!==undefined?hover.index===idx?it.backgroundColor:'transparent':'transparent', 
                margin:6, padding:0, 
                transition:'.5s ease',
            }}
            onMouseEnter={()=>setHover({index:idx})}
            onMouseLeave={()=>setHover({})}
        >
            <Link to={it.link}>
            <div style={{
                display:'flex',
                backgroundColor:hover.index===idx?'transparent':it.backgroundColor, 
                border:hover.index===idx?'10px solid ' + it.backgroundColor:'none', 
                width:'100%', margin:0, padding:30, 
                height:100,
                transition:'.5s ease',
                }}
            >
                <span style={{
                        margin:'auto',
                        color:hover.index===idx?'black':'white', 
                        fontSize:hover.index===idx?'small':'x-large', 
                        // transition:'.9s ease',
                        zIndex:10,
                }}>
                    {hover.index===idx?it.helpText['EN']:it.title['EN']}
                </span>
            </div>
            </Link>
        </div>  
        )}  
    </div>
  )
}

export default ImageNavbar
